.Welcome {
    margin: auto;
    width: 1010px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 140px;
}

.welcome-header {
    text-align: center;
    width: 700px;
    font-size: 32px;
    margin-bottom: 30px;
}

.welcome-header .highlight {
    font-weight: 800;
}

.Welcome .sub-text {
    color: var(--textColor);
    font-size: 14px;
    text-align: center;
    width: 980px;
    margin-bottom: 40px;
}

.Welcome .treat-point {
    display: flex;
    gap: 10px;
}

@media screen and (min-width: 200px) and (max-width: 1199px) {
    .Welcome {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98vw;
    }
    .Welcome .welcome-header {
        width: 90vw;
        font-size: 30px;
    }

    .Welcome .sub-text {
        width: 90vw;
    }

    .Welcome .treat-point {
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .Welcome .treat-point .point-icon {
        font-size: 12px;
        margin-top: 4px;
    }

    .treat-point .point-text {
        width: 90vw;
    }
}
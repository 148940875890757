.scroll-top {
    background: var(--bgColor);
    border-radius: 30px;
    width: 60px;
    height: 60px;
    font-size: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1500;
    transition: all 0.6s ease-in-out;
}

.scroll-top:hover {
    background: var(--subColor);
}
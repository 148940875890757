/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .DrugsAlcoholAddiction .landHero {
        height: 1018px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .DrugsAlcoholAddiction .Welcome {
        width: 96vw;
    }
}
.Newsletter {
    background-color: var(--primaryColor);
    height: 500px;
    width: 100%;
    margin-bottom: 190px;
}
    
.Newsletter .news-content {
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 1200px;
    gap: 107px;
    justify-content: center;
    align-items: center;
}

.left-n .news-banner {
    border-radius: 30px;
    margin-top: -140px;
    margin-left: -20px;
}

.right-news .news-text {
    font-size: 14px;
    color: white;
    margin-bottom: 20px;
}

.right-news .news-header {
    font-size: 24px;
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Newsletter {
        height: inherit;
        height: 400px;
        display: flex;
        justify-content: center;
    }

    .Newsletter .news-content {
        width: 92vw;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .news-content .left-n {
        display: none;
    }

    .right-news .news-header,
    .right-news .news-text
    {
        width: 88vw;
    }
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .Newsletter {
        height: 880px;
    }

    .Newsletter .news-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 96vw;
        gap: 60px;
    }

    .left-n .news-banner {
        width: 90vw;
        margin-left: 0;
    }

    .right-news .news-text, 
    .right-news .news-header
    {
        width: 90vw;
        text-align: center;
        margin: auto;
        margin-bottom: 20px;
    }

    .right-news .new-padding {
        padding-bottom: 20px;
    }

    .right-news .join-text {
        margin-bottom: 50px;
    }
}
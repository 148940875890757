.ContactUsContent {
    margin: auto;
    width: 1300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 140px;
}

.ContactUsContent .contact-left {
    width: 640px;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 20px;
}

.contact-left .contact-sub-header {
    font-size: 14px;
    font-weight: 500;
    color: var(--primaryColor);
}

.contact-left .contact-header {
    font-size: 36px;
    font-weight: 700;
    color: var(--textColor);
    width: 445px;
    margin-bottom: 20px;
}

.contact-left .contact-text {
    width: 530px;
    color: var(--textColor);
    font-size: 14px;
    margin-bottom: 70px;
}

.contact-left .seperator {
    width: 640px;
    height: 2px;
    background-color: var(--subColor);
    margin-bottom: 60px;
}


/* Form */
form label {
    font-size: 14px;
    color: var(--primaryColor);
}

form label .highlight {
    color: red;
}

form .two-col {
    display: flex;
    gap: 20px;
}

form .two-col input {
    width: 298px;
    height: 48px;
    background-color: var(--containerColor);
    border: none;
    border-radius: 30px;
    padding-left: 22px;
    margin: 10px 0 24px;
}

input::placeholder {
    font-size: 12px;
}

form .one-col input {
    width: 620px;
    height: 48px;
    background-color: var(--containerColor);
    border: none;
    border-radius: 30px;
    padding-left: 22px;
    margin: 10px 0 24px;
}

form .one-col textarea {
    margin: 10px 0 20px;
    background-color: var(--containerColor);
    border: none;
    border-radius: 30px;
    padding-left: 22px;
    padding-top: 10px;
    width: 620px;
}

/* end Form */


.ContactUsContent .contact-right {
    background-image: url(../../images/hero-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 490px;
    height: 1320px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.contact-right .top .contact-img-bg {
    position: relative;
    width: 460px;
    z-index: 1;
}

.contact-right .top .contact-banner {
    position: absolute;
    z-index: 2;
    width: 460px;
    margin-top: 30px;
    margin-left: 30px;
    border-radius: 8px 80px;
}

.contact-right .mid {
    margin: 120px 0;
}

.mid .contact-pill {
    width: 490px;
    height: 120px;
    border-radius: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
}

.mid .contact-pill .circle {
    display: flex;
    margin-left: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 76px;
    height: 76px;
}

.contact-pill .pill-content .pill-header {
    font-size: 16px;
    font-weight: 600;
    color: var(--primaryColor);
}

.contact-pill .pill-content .pill-text {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
}

.contact-right .contact-footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-right .contact-footer .seperator {
    margin: auto;
    display: flex;
    width: 300px;
    height: 2px;
    background-color: var(--subColor);
    margin-bottom: 26px;
}

.contact-footer .contact-footer-icons {
    display: flex;
    flex-direction: row;
    gap: 26px;
    align-items: center;
    justify-content: center;
}

.contact-footer-icons .cir {
    width: 70px;
    height: 70px;
    background-color: var(--subColor);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.contact-footer-icons .cir:hover {
    background-color: var(--primaryColor);
}

.cir .contact-icon:hover {
    color: white;
}

.cir .contact-icon {
    font-size: 34px;
    opacity: 80%;
    color: var(--textColor);
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .ContactUsContent .contact-left .email-section {
        margin-top: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90vw;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 1199px) {
    .ContactUsContent {
        width: 100%;
        height: 400px;
        background-image: url(../../images/hero-bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 1200px;
    }

    .ContactUsContent .contact-left {
        width: 96vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        margin: auto;
        padding-top: 140px;
    }

    .contact-left .contact-sub-header {
        color: var(--subColor);
        text-align: center;
    }

    .contact-left .contact-header {
        color: white;
        font-size: 30px;
        text-align: center;
        width: 92vw;
        margin-bottom: 0;
    }

    .contact-left .contact-text {
        width: 92vw;
        color: white;
        text-align: center;
    }

    .contact-left .seperator {
        display: none;
    }

    .contact-left .email-section {
        margin: auto;
        width: 96vw;
        justify-content: center;
        align-items: center;
        
    }

    .contact-left .email-section .contact-header {
        margin-top: 30px;
        color: var(--textColor);
        font-size: 30px;
        margin-bottom: 20px;
    }


    /* Form */

    form .two-col {
        display: flex;
        flex-direction: column;
        gap: 0;
        row-gap: 0px;
    }

    form .two-col input,
    form .one-col input,
    form .one-col textarea
    {
        width: 90vw;
    }
    /* end Form */

    .ContactUsContent .contact-right {
        display: none;
    }

    /* .email-section .contactUs-form {
        margin-bottom: 80px;
    } */
}
.Feedbacks {
    display: flex;
    margin: auto;
    margin-bottom: 140px;
    width: 1220px;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
}

.Feedbacks .feedback-img {
    display: flex;
    gap: 20px;
}

.feedback-img .line-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 400px;
}

.line-one .green-line {
    width: 36px;
    height: 100px;
    background-color: var(--primaryColor);
    border-radius: 30px;
}

.feedback-img .line-two {
    width: 36px;
    height: 400px;
    background-color: var(--subColor);
    border-radius: 30px;
}

.line-one .gray-line {
    width: 36px;
    height: 80px;
    border-radius: 30px;
    background-color: gray;
}

.feedback-img .line-three .feedback-banner {
    border-radius: 30px;
}

.Feedbacks .feedback-content .feedback-title {
    font-size: 40px;
    color: var(--primaryColor);
    margin-bottom: 30px;
}

.Feedbacks .feedback-content .feedback-text {
    font-size: 14px;
    color: var(--textColor);
    text-align: justify;
    width: 520px;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Feedbacks {
        width: 96vw;
    }
    
    .feedback-img .line-one {
        height: 260px;
        width: 20px;
    }

    .feedback-img .line-two,
    .feedback-img .line-one
    {
        display: none;
    }

    .Feedbacks .feedback-img {
        gap: 12px;
    }

    .line-one .green-line {
        width: 20px;
        height: 60px;
    }

    .line-one .gray-line {
        width: 20px;
    }

    .line-three .feedback-banner {
        width: 220px;
        height: 260px;
        object-fit: cover;
    }

    .Feedbacks .feedback-content {
        width: 94vw;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .Feedbacks {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        width: 96vw;
    }

    .Feedbacks .feedback-img {
        column-gap: 12px;
    }

    .feedback-img .line-one {
        height: 200px;
    }

    .line-one .green-line {
        width: 20px;
        height: 50px;
    }

    .line-one .gray-line {
        width: 20px;
        height: 40px;
    }

    .feedback-img .line-two {
        width: 20px;
        height: 200px;
    }

    .feedback-img .line-three .feedback-banner {
        width: 268px;
    }

    .Feedbacks .feedback-content {
        display: flex;
        flex-direction: column;
        width: 94vw;
        justify-content: center;
        align-items: center;
    }

    .Feedbacks .feedback-content .feedback-title {
        font-size: 30px;
        text-align: center;
    }

    .Feedbacks .feedback-content .feedback-text {
        width: 90vw;
        text-align: center;
    }
}
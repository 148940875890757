.CoreValues {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 140px;
    width: 1020px;
    text-align: center;
}

.CoreValues .core-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.CoreValues .core-top .in-p-title {
    width: 460px;
    color: var(--primaryColor);
    font-size: 32px;
    margin-bottom: 20px;
}

.CoreValues .core-top .title-pill {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 235px;
    height: 58px;
    padding: 8px 30px;
    font-size: 26px;
    font-weight: 700;
    color: var(--primaryColor);
    border-width: 1px;
    border-style: solid;
    border-radius: 80px;
    border-color: var(--subColor);
    margin-bottom: 30px;
}

.CoreValues .core-top .text {
    font-size: 14px;
    width: 580px;
    color: var(--textColor);
    text-align: center;
}


/* Bottom */
.CoreValues .core-bottom  {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1290px;
    gap: 38px;
}

.CoreValues .core-bottom .value-box {
    width: 440px;
    border-radius: 30px;
    background: #FFFFFF;
    box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    text-align: left;
    padding: 24px;
}

.value-box .value-b-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
}

.value-b-title .pcp-border {
    margin-bottom: 0!important;
    width: 60px!important;
    height: 60px!important;
    border-radius: 16px!important;
    border-width: 2px!important;
}

.value-b-title .pcp-title {
    width: 160px;
    margin-top: 0;
    font-size: 16px!important;
}

.value-box .value-icon {
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 37px;
    border-radius: 8px;
    border-color: var(--subColor);
    margin-bottom: 20px;
}

.value-box .value-icon .in-p-icon {
    width: 42px;
    opacity: 0.8;
}

.value-box .box-title {
    font-size: 14px;
    color: var(--primaryColor);
    font-weight: 700;
}

.value-box .box-text {
    font-size: 14px;
    color: var(--textColor);
    margin: 14px 0;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .CoreValues {
        width: 96vw;
        margin-bottom: 120px;
    }
    
    .CoreValues .core-bottom {
        width: 96vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .CoreValues .core-bottom .value-box {
        width: 36vw;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .CoreValues {
        width: 96vw;
        margin-bottom: 120px;
    }

    .CoreValues .core-top .title-pill {
        font-size: 22px;
        width: 180px;
        height: 40px;
    }

    .CoreValues .core-top .text {
        width: 94vw;
    }

    .CoreValues .core-top .in-p-title {
        font-size: 28px;
        width: 90vw;
    }

    /* Bottom */
    .CoreValues .core-bottom  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 36px;
    }

    .CoreValues .core-bottom .value-box {
        width: 70vw;
        box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.1);
    }
}
.Hero {
    margin: auto;
    background-image: url(../../images/hero-bg.jpg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    width: 1240px;
    height: 670px;
    border-radius: 30px;
    padding: 16px 30px;
    margin-bottom: 140px;
}


/* Left Side */
.Hero .l-side {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.l-side .hero-title {
    font-weight: 600;
    font-size: 12px;
    color: var(--subColor);
}

.l-side .hero-head-text {
    width: 420px;
    font-family: "Poppins";
    font-weight: 800;
    color: white;
    text-align: justify;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: -10px;
}

.l-side .hero-text {
    width: 556px;
    color: white;
    font-size: 14px;
    text-align: justify;
    margin: 30px 0px;
    line-height: 26px;
}

.Hero .l-side .btn {
    width: 110px;
}


/* Right Side */
.Hero .r-side {
    position: relative;
    width: 495px;
    height: 530px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.r-side .stack {
    position: absolute;
}

.r-side .hero-image {
    z-index: 2;
    border-radius: 8px 30px;
    width: 420px;
}

.r-side .img-border {
    z-index: 1;
    width: 420px;
    top: 70px;
    left: 70px;
}

.r-side .img-bg {
    z-index: 0;
    width: 420px;
    top: 10px;
    left: 8px;
}


/* large Tablet styles properties */

@media screen and (min-width: 600px) and (max-width: 1119px) {
    .Hero {
        border-radius: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
        padding-top: 120px;
    }

    .Hero .l-side {
        width: 90vw;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    .l-side .hero-head-text {
        margin-top: 20px;
        width: 70vw;
        text-align: center;
    }

    .l-side .hero-text {
        width: 70vw;
        text-align: center;
    }

    .Hero .r-side {
        width: 90vw;
        display: none;
    }
}


@media screen and (min-width: 600px) and (max-width: 1119px) {
    .Hero {
        border-radius: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
        padding-top: 120px;
    }
    .Hero .l-side {
        width: 90vw;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .l-side .hero-head-text {
        margin-top: 20px;
        width: 70vw;
        text-align: center;
    }
    .l-side .hero-text {
        width: 70vw;
        text-align: center;
    }
    .Hero .r-side {
        width: 90vw;
        display: none;
    }
}


/* Mobile styles properties */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .Hero {
        border-radius: 0px;
        width: 100%;
        height: 900px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 140px;
        padding: 0;
        padding-top: 160px;
    }

    .Hero .l-side {
        width: 360px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Hero .l-side .hero-head-text {
        text-align: center;
        width: 320px;
        font-size: 30px;
        line-height: 36px;
    }

    .Hero .l-side .hero-text {
        text-align: center;
        width: 320px;
        font-style: 14px;
        line-height: 20px;
    }


    .Hero .r-side {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 360px;
        text-align: center;
    }



    .Hero .r-side {
        max-width: 360px;
    }

    .r-side .hero-image {
        top: 40px;
        width: 290px;
    }

    .r-side .img-border {
        width: 290px;
        top: 56px;
        left: 48px;
        /* display: none; */
    }

    .r-side .img-bg {
        width: 290px;
        top: 24px;
        left: 18px;
        /* display: none; */
    }

}
.FAQs {
    margin: auto;
    margin-bottom: 140px;
    width: 1240px;
}

.FAQs .faqs-title {
    font-size: 40px;
    color: var(--primaryColor);
    width: 400px;
    font-weight: 800;
    margin-bottom: 30px;
}

.FAQs .faqs-content {
    display: flex;
    flex-direction: row;
    gap: 120px;
}

.faqs-content .left-faq {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.left-faq .faq-pill1 {
    border-radius: 16px;
    background-color: var(--containerColor);
    width: 400px;
    height: 106px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: flex-start;

}

.faq-pill1 .pill-icon img {
    width: 40px;
    margin-left: 20px;
}

.pill-content .title {
    font-size: 20px;
    font-weight: 500;
    color: var(--primaryColor);
    margin-bottom: 10px;
}

.pill-content .text {
    font-size: 14px;
    font-weight: 400;
    color: var(--textColor);
}

.left-faq .faq-pill2 {
    border-radius: 16px;
    background-image: url(../../images/hero-bg.jpg);
    width: 400px;
    height: 106px;
    display: flex;
    flex-direction: row;
    gap: 26px;
    align-items: center;
    justify-content: flex-start;
    background-size: cover;
    background-repeat: no-repeat;
}

.faq-pill2 .pill-icon {
    margin-left: 20px;
    background-color: rgba(217, 217, 217, 0.75);
    height: 62px;
    width: 62px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq-pill2 .pill-content .title {
    color: var(--subColor);
    font-size: 20px;
    width: 160px;
}



/* Right Side */

.faq-items .accordion {
    width: 660px;
}

.accordion .accordionItem {
    margin-bottom: 16px;
}

.accordionItem .accordionButton {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    gap: 16px;
}

.accordionButton .question {
    margin-top: -2px;
    font-size: 16px;
    color: var(--primaryColor);
    font-weight: 500;
}

.accordionButton .question:hover {
    color: var(--subColor);
}

.accordionItem .answer {
    font-size: 14px;
    margin-left: 60px;
    margin-top: 10px;
    margin-bottom: 24px;
    color: gray;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .FAQs {
        width: 90vw;
    }

    .faqs-content .left-faq {
        display: none;
    }

    .accordionItem .answer {
        width: 80vw;
        text-align: justify;
    }
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .FAQs {
        width: 96vw;
        margin-bottom: 600px;
    }

    .FAQs .faqs-title {
        width: 90vw;
        font-size: 30px;
        text-align: center;
        display: flex;
        margin: auto;
        margin-bottom: 20px;
    }

    .FAQs .faqs-content {
        display: flex;
        flex-direction: column;
        width: 94vw;
        gap: 60px;
    }

    .left-faq .faq-pill1,
    .left-faq .faq-pill2
    {
        width: 96vw;
        gap: 20px;
    }

    .faq-items .accordion {
        width: 94vw;
    }
}
.Seperator {
    margin: auto;
    width: 100%;
    background-image: url(../../images/Seperator.png);
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 60px;
}

/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Seperator {
        width: 100%;
        height: 200px;
        background-image: url(../../images/Seperator.png);
        background-repeat: no-repeat;
        margin-bottom: 40px;
        background-size: cover;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .Seperator {
        width: 100%;
        height: 99px;
        background-image: url(../../images/Seperator.png);
        background-repeat: no-repeat;
        margin-bottom: 40px;
        background-size: cover;
    }
}
.BipolarDisorder .Welcome .sub-text {
    margin-bottom: 6px;
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .BodyDysmorphicDisorder .landHero {
        height: 1010px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .BodyDysmorphicDisorder .Welcome {
        width: 96vw
    }
}
.OurTeam {
    margin: auto;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 140px;
}

.OurTeam .section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.OurTeam .text {
    width: 40vw;
    text-align: center;
    font-size: 14px;
    color: var(--textColor);
    margin-bottom: 80px;
}

.OurTeam .team-cards {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 90vw;
    flex-wrap: wrap;
    justify-content: center;
}

.team-cards .team-card {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-bottom: 60px;
    cursor: pointer;
}

.team-cards .team-card .team-role {
    font-size: 14px;
    color: #545454;
    text-align: center;
}

.team-cards .team-card .team-name {
    margin: 28px 0 6px;
    color: var(--primaryColor);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.OurTeam .title-pill {
    margin-top: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 235px;
    height: 58px;
    padding: 8px 30px;
    font-size: 26px;
    font-weight: 700;
    color: var(--primaryColor);
    border-width: 1px;
    border-style: solid;
    border-radius: 80px;
    border-color: var(--subColor);
    margin-bottom: 30px;
    cursor: pointer;
}

.OurTeam .title-pill:hover {
    background: var(--primaryColor);
    color: white;
    border-color: var(--primaryColor);
}


/* Book a Session Modal 
Modal (background) */
.team-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 150000; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.team-modal.show {
    display: block; /* Show modal */
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    border-radius: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 2px 16px;
    background-color: var(--bgColor);
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    color: white;
    text-align: center;
}

.team-modal-body {
    margin: 20px 0;
    padding: 2px 16px; 
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.team-modal-body .mb-l-side img {
    width: 300px;
    border-radius: 30px;
}

.team-modal-body .mb-r-side {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.team-modal-body .mb-r-side .modal-role {
    font-size: 16px;
    color: var(--primaryColor);
    font-weight: 600;
}

.team-modal-body .mb-r-side .modal-bio {
    margin-top: 10px;
    font-size: 14px;
    color: var(--textColor);
    text-align: justify;
}

.modal-footer {
    padding: 2px 16px;
    background-color: #e5e5e5;
    color: gray;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    font-size: 10px;
    text-align: center;
}

.modal-footer p {
    cursor: pointer;
}

.modal-footer p:hover {
    color: #004d00;
}

/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .OurTeam {
        width: 90vw;
    }

    .team-modal .modal-content {
        width: 96%;
        height: 80vh;
        overflow-y: auto;
        box-sizing: border-box;
    }
    
    .modal-content .team-modal-body {
        display: flex;
        flex-direction: column;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .OurTeam {
        display: flex;
        flex-direction: column;
        width: 90vw;
    }

    .OurTeam .section-title .title {
        font-size: 30px;
    }

    .OurTeam .text  {
        width: 76vw;
    }

    .OurTeam .team-cards {
        width: 90vw;
    }

    .OurTeam .title-pill {
        font-size: 22px;
        width: 180px;
        height: 40px;
    }

    .team-modal .modal-content {
        width: 96%;
        height: 82vh;
        overflow-y: auto;
        box-sizing: border-box;
    }

    .team-modal .modal-header {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .team-modal .modal-content .modal-header h2 {
        font-size: 32px;
        text-align: center;
    }

    .team-modal .modal-content .team-modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .team-modal-body .mb-r-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
        margin-bottom: 10px;
    }

    .team-modal-body .mb-r-side .modal-role {
        text-align: center;
    }

    ::-webkit-scrollbar{
        height: 2px;
        width: 3px;
    }
}
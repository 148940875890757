.wrapper {
    width: min(900px, 100% - 3rem);
    margin-inline: auto;
    margin-top: -40px;
}


/* Currency Toggle */

.naira, .pounds {
    transition: background-color 0.3s ease;
}

.toggle-button-cover {
    display: flex;
    position: relative;
    height: 70px;
    box-sizing: border-box;
  }
  
  .button-cover {
    height: 100px;
    margin: 20px;
    background-color: #fff;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;
  }
  
  .button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 14px;
    line-height: 1;
    padding: 5px;
  }
  
  .button-cover,
  .naira,
  .pounds {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .naira {
    z-index: 2;
  }
  
  .pounds {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  
  .button.r,
  .button.r .pounds {
    border-radius: 100px;
  }
  
  #button-3 .naira:before {
    content: "₦";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: var(--bgColor);
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }
  
  #button-3 .checkbox:active + .naira:before {
    width: 46px;
    border-radius: 100px;
  }
  
  #button-3 .checkbox:checked:active + .naira:before {
    margin-left: -26px;
  }
  
  #button-3 .checkbox:checked + .naira:before {
    content: "£";
    left: 42px;
    background-color: var(--subColor);
  }
  
  #button-3 .checkbox:checked ~ .pounds {
    background-color: #ebf7fc;
  }

/* end Currency Toggle */

.PricingTable .toggle-nheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -26px;
}

.PricingTable .table-currency {
  font-size: 16px;
  color: var(--subColor);
  font-weight: 500;
}

.table-caption {
    font-size: 22px;
    color: var(--textColor);
    font-weight: 600;
    margin: 20px 0;
}

.PricingTable table {
    width: 100%;
    border-collapse: collapse;
    background: var( --containerColor);
    border-radius: 20px;
    margin-bottom: 80px;
}

th, td {
    padding: 1rem;
    text-align: left;
}

.PricingTable .table-wrapper {
    overflow: hidden;
    border-radius: 20px 20px 0 0;
}

table .table-head {
    font-size: 18px;
    color: white;
    font-weight: 600;
    background-color: var(--primaryColor);
}

table .price-item {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

table .price-item:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: white;
}

tr td {
    font-size: 14px;
    color: var(--textColor);
}

tr td .note {
    font-size: 10.5px;
}

tr:nth-of-type(2n) {
    background: hsl(0 0% 0% / 0.03);
    overflow: hidden;
}

.PricingTable .policy-doc {
  font-size: 14px;
  margin-bottom: 32px;
  margin-top: -52px;
  color: var(--textColor);
}

.policy-doc a {
  color: var(--primaryColor);
}

/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
  .pricing-cta {
    flex-direction: column;
    margin: 60px 0 80px;
  }

  .pricing-cta .btn {
    margin-top: -90px;
  }
}
.TreatmentPlan {
    margin: auto;
    display: flex;
    width: 1220px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    gap: 30px;
}

.TreatmentPlan .phistory-right-side {
    height: 600px;
}

.TreatmentPlan .phistory-left-side .btn {
    width: 100px;
}

.TreatmentPlan .phistory-left-side .pcpn-text {
    color: var(--primaryColor);
    margin-bottom: 12px;
    /* text-align: center; */
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .TreatmentPlan {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96vw;
    }

    .phistory-right-side .title-ring {
        width: 500px;
    }

    .phistory-right-side .title-ring .title {
        text-align: center;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .TreatmentPlan {
        width: 96vw;
        display: flex;
        flex-direction: column-reverse;
        row-gap: 30px;
    }

    .TreatmentPlan .phistory-left-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .TreatmentPlan .phistory-left-side .pcpn-text {
        text-align: center;
    }

    .TreatmentPlan .phistory-left-side .htext {
        text-align: center;
        width: 88vw;
        margin-top: 20px;
    }

    .TreatmentPlan .phistory-right-side {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 400px;
    }

    .phistory-right-side .title-ring {
        width: 92vw;
    }

    .phistory-right-side .title-ring .title {
        text-align: center;
    }
}
header {
    position: -webkit-sticky;
    position: sticky;
    z-index: 999;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1240px;
    border-radius: 40px;
    padding: 16px 30px;
    background-color: #e5e5e5;
    transition: all 0.6s ease-in-out;
  }

  header .header-logo a .logo {
    width: 150px;
  }
  
  .with-shadow {
    top: 8px;
    box-shadow: 0 5px 15px rgba(54, 54, 54, 0.12);
    background-color: white;
    transition: all 0.6s ease-in-out;
  }
  
  header .menu-links {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  header .Buttons {
    display: flex;
    gap: 12px;
  }
  
  /* Hide Mobile Menu Elements */
  .Mobile-Buttons, 
  #show-mobile-menu,
  .menu,
  .header-logo .m-logo {
    display: none;
  }
  
  ul .Link {
    cursor: pointer;
    font-size: 14px;
    color: var(--textColor);
    white-space: nowrap;
    transition: 0.3s ease-in-out;
    position: relative;
  }
  
  .drop-down {
    display: flex;
    column-gap: 6px;
    justify-content: center;
  }
  
  ul .Link:hover,
  ul.active {
    color: var(--primaryColor);
    font-weight: 500;
  }
  
  ul .Link.active {
    color: var(--primaryColor);
    font-weight: 500;
  }
  
  .menu-links .Link:hover::after,
  .menu-links .Link.active::after {
    content: "";
    font-weight: 500;
    color: var(--primaryColor);
    width: 100%;
    height: 2px;
    background-color: var(--primaryColor);
    position: absolute;
    bottom: -6px;
    left: 0;
    transform: translateX(-1%);
  }
  
  /* Dropdown Menu */
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: -50px;
    background: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px 0;
    margin: 0;
    border-radius: 16px;
    z-index: 999;
    width: 230px;
  }
  
  .dropdown-menu .dropdown-item {
    padding: 6px 20px;
    white-space: nowrap;
    display: block;
    font-size: 12px;
    color: var(--textColor);
    text-decoration: none;
  }
  
  .dropdown-menu .dropdown-item:hover {
    background-color: #f0f0f0;
    color: var(--primaryColor);
  }
  
  /* Show dropdown on hover */
  .drop-down:hover .dropdown-menu {
    display: block;
  }

   /* small Tablet styles properties */
  @media screen and (min-width: 600px) and (max-width: 1119px) {
    header {
      display: flex;
      margin: auto;
      position: fixed;
      top: 0;
      height: 80px;
      width: 98vw;
      background-color: transparent;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;
      padding: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    header .header-logo .logo,
    header .Buttons {
      display: none;
    }
  
    header .header-logo .m-logo {
      display: block;
      width: 140px;
      margin-left: 20px;
    }
  
    header .menu {
      display: block;
      text-align: center;
    }
  
    .menu #bar {
      color: white;
      font-size: 20px;
      margin-right: 20px;
    }
  
    .with-shadow {
      top: 8px;
      box-shadow: 0 5px 15px rgba(54, 54, 54, 0.12);
      background-color: var(--bgColor);
      transition: all 1.2s ease-in-out;
      border-radius: 50px;
    }
  
    header ul {
      position: fixed;
      top: 86px;
      right: -110%;
      background: rgba(255, 255, 255, 0.97);
      box-shadow: -5px 0 15px rgba(0, 0, 0, 0.12);
      height: 100vh;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 60px;
      transition: right 0.5s linear;
      gap: 1.6rem;
      z-index: 55;
    }
  
    ul .Link {
      font-size: 1rem;
    }
  
    .menu-links .Mobile-Buttons {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }
  
    #show-mobile-menu:checked ~ ul {
      right: 0%;
    }

    .dropdown-menu {
        width: 238px;
    }
  }
  
  /* Mobile styles properties */
  
  @media screen and (min-width: 200px) and (max-width: 1199px) {
    header {
      display: flex;
      margin: auto;
      position: fixed;
      top: 0;
      height: 80px;
      width: 98vw;
      background-color: transparent;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;
      padding: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    header .header-logo .logo,
    header .Buttons {
      display: none;
    }
  
    header .header-logo .m-logo {
      display: block;
      width: 140px;
      margin-left: 20px;
    }
  
    header .menu {
      display: block;
      text-align: center;
    }
  
    .menu #bar {
      color: white;
      font-size: 20px;
      margin-right: 20px;
    }
  
    .with-shadow {
      top: 8px;
      box-shadow: 0 5px 15px rgba(54, 54, 54, 0.12);
      background-color: var(--bgColor);
      transition: all 1.2s ease-in-out;
      border-radius: 50px;
    }
  
    header ul {
      position: fixed;
      top: 86px;
      right: -110%;
      background: rgba(255, 255, 255, 0.97);
      box-shadow: -5px 0 15px rgba(0, 0, 0, 0.12);
      height: 100vh;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 60px;
      transition: right 0.5s linear;
      gap: 1.6rem;
      z-index: 55;
    }
  
    ul .Link {
      font-size: 1rem;
    }
  
    .menu-links .Mobile-Buttons {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }
  
    #show-mobile-menu:checked ~ ul {
      right: 0%;
    }

    .dropdown-menu {
        width: 238px;
    }
  }
  
.Neurological {
    width: 100%;
    background-color: var(--containerColor);
    margin-bottom: 140px;
    height: 440px;
}

.Neurological .neu-cont {
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 1220px;
    justify-content: center;
    align-items: center;
}

.right-neuro .neuro-title {
    color: var(--primaryColor);
    font-size: 32px;
    margin-bottom: 20px;
}

.right-neuro .neuro-text {
    text-align: justify;
    font-size: 14px;
    color: var(--textColor);
    width: 560px;
}

.left-neuro .pcp-ba {
    width: 636px;
    height: 439px;
    object-fit: cover;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Neurological {
        display: flex;
        height: inherit;
        padding-bottom: 60px;
        /* gap: 0; */
    }

    .Neurological .neu-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .neu-cont .left-neuro {
        width: 100vw;
    }

    .left-neuro .neuro-banner {
        width: 100vw;
        display: none;
    }

}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .Neurological {
        margin-bottom: 120px;
        height: 600px;
    }

    .pcp-neuro {
        height: 900px;
    }

    .Neurological .neu-cont {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        width: 100%;
    }

    .neu-cont .left-neuro .neuro-banner {
        width: 100%;
    }

    .neu-cont .right-neuro {
        width: 94vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .right-neuro .neuro-title {
        font-size: 28px;
        text-align: center;
    }

    .right-neuro .neuro-text {
        width: 90vw;
        text-align: center;
    }
}
.History {
    margin: 0 auto;
    padding-top: 20px;
    background-image: url(../../images/history-bg.jpg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 180px;
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
}

.History .scroll {
    display: flex;
    flex-direction: column;
    gap: 68px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.History .scroll .scroll-text {
    transform: rotate(-90deg);
    white-space: nowrap;
    color: var(--subColor);
    font-size: 18px;
    font-weight: 300;
    position: relative;
}

.History .scroll .s-down {
    width: 14px;
    position: relative;
}

.History .l-side {
    display: flex;
    flex-direction: column;
    gap: 38px;
    margin-top: 160px;
    margin-right: 150px;
    margin-left: 8px;
}

.History .l-side .h-text {
    font-size: 20px;
    font-weight: 700;
    color: white;
    text-align: justify;
    width: 320px;
    line-height: 36px;
}

.History .l-side img {
    width: 120px;
}

.History .r-side {
    width: 780px;
    margin-top: 4px;
}

.History .r-side .text {
    width: 740px;
    font-size: 15px;
    font-weight: 400;
    color: white;
    margin-bottom: 24px;
}

.History .r-side .btn {
    width: 90px;
    margin-bottom: 30px;
}

.r-side .sessions {
    margin-top: 56px;
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.sessions .session {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.r-side .sessions .session .session-header span {
    font-size: 36px;
    color: var(--subColor);
    font-weight: 900;
}

.r-side .sessions .session .s-text {
    font-size: 18px;
    font-weight: 300;
    color: white;
}

.r-side .sessions .session .session-title {
    color: white;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
}


@media screen and (min-width: 600px) and (max-width: 1199px) {
    .History {
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 30px;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 30px;
    }

    .History .scroll {
        display: none;
    }

    .l-side img {
        display: none;
    }

    .History .l-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        width: 90vw;
    }

    .History .l-side .h-text {
        width: 90vw;
        /* text-align: center; */
        margin-bottom: 20px;
        font-size: 26px;
    }

    .History .r-side {
        width: 90vw;
    }

}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .History {
        display: flex;
        flex-direction: column;
        height: 860px;
        margin-top: 140px;
    }

    .History .scroll {
        display: none;
    }

    .History .l-side {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 30px;
        width: 86vw;
        margin-top: 0;
    }

    .History .l-side .h-text {
        text-align: center;
    }

    .History .l-side img {
        display: none;
    }

    .History .r-side {
        width: 86vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .History .r-side .text {
        width: 86vw;
        margin-top: 8px;
        text-align: center;
        margin-bottom: 20px;
    }

    .History .r-side .btn {
        display: flex;
        justify-content: start;
    }

    .History .r-side .sessions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .History .r-side .sessions .session-title {
        width: 90px;
        text-align: center;
    }

}
.ApproachWelcome {
    margin: auto;
    display: flex;
    width: 1220px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 170px;
}

.ApproachWelcome .approach-welcome-left .section-title {
    margin-bottom: 30px;
}

.ApproachWelcome .approach-welcome-left .text {
    margin-bottom: 20px;
    width: 660px;
}

.approach-welcome-left .btn {
    width: 100px;
}

.ApproachWelcome .approach-welcome-right .welcome-banner {
    border-radius: 30px;
}

/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .ApproachWelcome {
        display: flex;
        flex-direction: column;
        width: 92vw;
        gap: 60px;
    }

    .ApproachWelcome .approach-welcome-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .approach-welcome-left .section-title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-title .title,
    .approach-welcome-left .text
    {
        text-align: center;
    }
}

/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .ApproachWelcome {
        width: 96vw;
        display: flex;
        flex-direction: column;
    }

    .ApproachWelcome .approach-welcome-left,
    .ApproachWelcome .approach-welcome-right
    {
        width: 94vw;
    }

    .ApproachWelcome .approach-welcome-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    .approach-welcome-left .section-title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .approach-welcome-left .section-title .title {
        font-size: 30px;
        text-align: center;
    }

    .ApproachWelcome .approach-welcome-left .text {
        width: 92vw;
        text-align: center;
    }

    .ApproachWelcome .approach-welcome-right {
        margin: auto;
        width: 90vw;
    }

    .approach-welcome-right .welcome-banner {
        width: 88vw;
    }
}
.PersonalHistory {
    margin: auto;
    display: flex;
    width: 1220px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    gap: 30px;
}

.PersonalHistory .phistory-left-side {
    height: 600px;
}

.title-ring {
    width: 540px;
    height: 30px;
    background-color: var(--subColor);
    border-radius: 160px;
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-ring .title {
    font-size: 32px;
    color: var(--primaryColor);
    font-weight: 600;
    margin: 60px 0px 28px;
}

.title-ring .banner {
    width: 490px;
    border-radius: 30px;
}

.htext {
    font-size: 14px;
    color: var(--textColor);
    text-align: justify;
    margin-bottom: 20px;
}

.phistory-right-side .title {
    color: var(--primaryColor);
    margin-bottom: 10px;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .PersonalHistory {
        display: flex;
        width: 96vw;
        justify-content: center;
        align-items: center;
        gap: 24px;
    }

    .phistory-left-side .title-ring {
        width: 420px;
    }

    .title-ring .banner {
        width: 420px;
    }

    .PersonalHistory .phistory-right-side {
        margin-top: -60px;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .PersonalHistory {
        width: 96vw;
        display: flex;
        flex-direction: column;
        gap: 0;
        row-gap: 30px;
    }

    .PersonalHistory .phistory-left-side,
    .PersonalHistory .phistory-right-side {
        width: 94vw;
    }

    .PersonalHistory .phistory-left-side {
        height: 400px;
    }

    .phistory-left-side .title-ring {
        width: 92vw;
    }

    .title-ring .title {
        font-size: 28px;
        text-align: center;
    }

    .title-ring .banner {
        width: 90vw;
    }

    .phistory-right-side .title {
        text-align: center;
    }

    .phistory-right-side .htext {
        width: 88vw;
        text-align: center;
    }
}
.FeedbackTwo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 140px;
    margin: auto;
    width: 1220px;
    margin-bottom: 140px;
}

.FeedbackTwo .int-pill {
    display: flex;
    width: 588px;
    height: 240px;
    background: white;
    box-shadow: 2px 2px 30px 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
}

.int-pill .pill-image {
    border-radius: 30px 0px 0px 30px;
}

.int-pill .pill-title {
    font-size: 14px;
    color: var(--primaryColor);
    font-weight: 600;
    margin-bottom: 10px;
}

.int-pill .pill-text {
    font-size: 14px;
    color: var(--textColor);
    text-align: justify;
    width: 340px;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .FeedbackTwo {
        display: flex;
        width: 96vw;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }

    .FeedbackTwo .int-pill {
        width: 470px;
        height: 260px;
    }

    .int-pill .pill-image {
        width: 150px;
        height: 260px;
        object-fit: cover;
    }

    .int-pill .pill-text {
        width: 270px;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .FeedbackTwo {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        width: 96vw;
        justify-content: center;
        align-items: center;
    }

    .FeedbackTwo .int-pill {
        display: flex;
        flex-direction: column;
        width: 92vw;
        justify-content: center;
        align-items: center;
        column-gap: 16px;
    }

    .int-pill .pill-image {
        display: none;
    }

    .int-pill .pill-title {
        text-align: center;
    }

    .int-pill .pill-text {
        width: 86vw;
        text-align: center;
    }
}
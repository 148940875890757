.Principles {
    margin: auto;
    margin-top: 240px;
    width: 1260px;
    margin-bottom: 280px;
}

.Principles .top-prin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 120px;
}

.top-prin .t-title {
    font-size: 14px;
    font-weight: 700;
    color: var(--textColor);
}

.top-prin .t-title .highlight {
    font-weight: 700;
}

.top-prin .b-title {
    margin-top: 10px;
    font-size: 32px;
    font-weight: 700;
    color: var(--primaryColor);
}

.Principles .top-prin .text {
    font-size: 14px;
    color: var(--textColor);
    max-width: 580px;
    margin: 26px 0;
    text-align: center;
}

.Principles .bottom-prin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
}


/* Left Card */
.bottom-prin .card-l {
    position: relative;
    width: 420px;
    height: 680px;
    border-radius: 30px;
    background: var(--containerColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 90px;
}


/* Left inside */
.bottom-prin .card-l .banner {
    border-radius: 30px;
    width: 320px;
    margin-top: -20px;
}

.bottom-prin .card-l .card-content {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
}

.card-content .card-text {
    width: 266px;
    color: var(--textColor);
    font-size: 14px;
}

.card-content .card-title {
    font-size: 14px;
    color: var(--textColor);
    font-weight: 700;
}

.card-title .highlight {
    font-size: 32px;
    color: var(--primaryColor);
    font-weight: 700;
}

.bottom-prin .card-l .btn {
    width: 100px;
    margin-top: 10px;
}

.card-l .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-l .card-footer .right-edge {
    display: none;
}


/* Middle card */
.bottom-prin .card-m {
    position: relative;
    width: 420px;
    height: 760px;
    border-radius: 30px;
    background: var(--containerColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 160px;
}

/* Mid inside */
.bottom-prin .card-m .banner {
    border-radius: 30px;
    width: 320px;
    margin-top: -20px;
}

.bottom-prin .card-m .card-content {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
}

.card-content .card-text {
    width: 266px;
    color: var(--textColor);
    font-size: 14px;
}

.card-content .card-title {
    font-size: 14px;
    color: var(--textColor);
    font-weight: 700;
}

.card-title .highlight {
    font-size: 32px;
    color: var(--primaryColor);
    font-weight: 700;
}

.bottom-prin .card-m .btn {
    width: 100px;
    margin-top: 10px;
}

.card-m .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* Right Card */
.bottom-prin .card-r {
    position: relative;
    width: 420px;
    height: 680px;
    border-radius: 30px;
    background: var(--containerColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 90px;
}


/* Left inside */
.bottom-prin .card-r .banner {
    border-radius: 30px;
    width: 320px;
    margin-top: -20px;
}

.bottom-prin .card-r .card-content {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
}

.card-content .card-text {
    width: 266px;
    color: var(--textColor);
    font-size: 14px;
}

.card-content .card-title {
    font-size: 14px;
    color: var(--textColor);
    font-weight: 700;
}

.card-title .highlight {
    font-size: 32px;
    color: var(--primaryColor);
    font-weight: 700;
}

.bottom-prin .card-r .btn {
    width: 100px;
    margin-top: 10px;
}

.card-r .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-r .card-footer .left-edge {
    opacity: 0%;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Principles {
        width: 90vw;
    }

    .Principles .bottom-prin {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        width: 86vw;
        align-items: flex-start;
        justify-content: space-around;
    }

    .bottom-prin .card-m {
        height: 680px;
    }

    .bottom-prin .card-m .card-content {
        margin-top: -88px;
    }

    .card-l .card-footer .right-edge,
    .card-r .card-footer .left-edge
    {
        display: block;
        opacity: 100%;
    }
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .Principles {
        width: 90vw;
    }

    .Principles .bottom-prin {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bottom-prin .card-l,
    .bottom-prin .card-r
    {
        width: 92vw;
    }

    .bottom-prin .card-m {
        width: 92vw;
    }

    .bottom-prin .card-l .banner, 
    .bottom-prin .card-r .banner, 
    .bottom-prin .card-m .banner 
    {
        width: 294px;
    }

    .card-l .card-footer .right-edge,
    .card-r .card-footer .left-edge
    {
        display: block;
        opacity: 100%;
    }
}
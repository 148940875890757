/* Zeroed properties */
* {
  margin: 0;
  padding: 0;
  outline: 0;
  appearance: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-decoration: none;
  list-style: none;
}

:root {
  --bgColor: #004d00;
  --subColor: #ffcd03;
  --primaryColor: #00ab4b;
  --textColor: #545454;
  --containerColor: rgba(0, 0, 0, 0.1);
}

/* Scroll Bar Effect */
::-webkit-scrollbar{
  height: 12px;
  width: 12px;
}
  
::-webkit-scrollbar-track{
  background: var(--containerColor);
  border-radius: 1ex;
}
  
::-webkit-scrollbar-thumb{
  background: var(--bgColor);
  border-radius: 1ex;
}


/* Text content style properties */
h1 {
  font-size: 36px;
  font-weight: 800;
  font-family: "Poppins";
  color: var(--textColor);
}

.highlight {
  color: var(--primaryColor);
}

.green-pill {
  cursor: pointer;
  background-color: #cceedb;
}

.white-pill {
  cursor: pointer;
  background-color: white;
}

.green-pill:hover {
  background-color: var(--subColor);
}

.white-pill:hover {
  background-color: var(--subColor);
}




/* Button style properties */
.btn {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px 48px;
  border-radius: 30px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
}

.btn-primary {
  background: var(--primaryColor);
  color: white;
  border-color: var(--primaryColor);
}

.btn-secondry {
  background: var(--subColor);
  border-color: var(--subColor);
  color: white;
}

.btnHL {
  color: var(--primaryColor);
  border-color: var(--primaryColor);
  background-color: transparent;
}

.btn:active {
  transform: scale(0.98);
}

.btn-primary:hover {
  background: var(--subColor);
  border-color: var(--subColor);
}

.btn-secondry:hover {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
}

.btnHL:hover {
  background: var(--primaryColor);
  color: white;
}


.btn-margin {
  margin: 26px 0;
  width: 100px;
  white-space: nowrap;
}

.lhero-banner {
  width: 380px;
  height: 407px;
  object-fit: cover;
}

.no-justify {
  text-align: left!important;
}





.container-bg {
  background: var(--containerColor);
}


/* Hero Section of Pages */
.landHero {
  background-image: url(../src/images/hero-bg.jpg);
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 1300px;
  height: 520px;
  margin-bottom: 140px;
  border-radius: 30px;
  gap: 80px;
}

.landHero .hero-left-side {
  width: 580px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 30px;
}

.hero-left-side .hero-head-text {
  font-family: "Poppins";
  font-weight: 800;
  color: white;
  text-align: justify;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: -10px;
}

.hero-left-side .hero-text {
  width: 580px;
  font-size: 14px;
  text-align: justify;
  color: white;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 20px;
}

.landHero .hero-left-side .btn {
  width: 120px;
}

.landHero .hero-right-side {
  position: relative;
  width: 495px;
  height: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 40px;
}

.hero-right-side .stack {
  position: absolute;
}

.hero-right-side .inner-hero {
  border-radius: 8px 80px;
  z-index: 2;
  width: 380px;
}

.hero-right-side .img-bg {
  width: 380px;
  margin-top: -40px;
  margin-left: -40px;
}

/* end Hero Section */


/* Section Title */

.section-title .title {
  color: var(--primaryColor);
  margin-bottom: 4px;
}

.section-title .line {
  width: 100px;
  height: 4px;
  background-color: var(--subColor);
}

.text {
  font-size: 14px;
  color: var(--textColor);
  text-align: justify;
  
}

.point-text {
  display: flex;
  font-size: 14px;
  color: var(--textColor);
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.text .point-icon {
  color: var(--bgColor);
  font-size: 12px;
}

.sub-text span {
  font-weight: 600;
  color: var(--primaryColor);
}

/* Book a Session Modal 
	Modal (background) */
  .book-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .book-modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    border-radius: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .book-modal-header {
    padding: 2px 16px;
    background-color: #004d00;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    color: white;
    text-align: center;
  }
  
  .book-modal-body {
    padding: 2px 16px; 
      text-align: center; 
      height: 280px;
  }
  
  .book-modal-footer {
    padding: 2px 16px;
    background-color: #e5e5e5;
    color: gray;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    font-size: 10px;
    text-align: center;
  }
  
  .book-modal-footer p {
    cursor: pointer;
  }
  
  .book-modal-footer p:hover {
    color: #004d00;
  }
  /* end Book a Session Modal */

.APersonalityDisorder .treatment {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
  .landHero {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .landHero .hero-left-side {
    width: 80vw;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
  }

  .hero-left-side .hero-text {
    text-align: center;
    width: 80vw;
  }

  .landHero .hero-right-side {
    display: none;
    width: 80vw;
  }
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
  .landHero {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 840px;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 160px;
    row-gap: 40px;
  }

  .landHero .hero-left-side {
    width: 96vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }

  .hero-left-side .hero-head-text,
  .hero-left-side .hero-text
  {
    text-align: center;
    width: 94vw;
  }

  .hero-left-side .hero-head-text {
    font-size: 30px;
  }

  .landHero .hero-right-side {
    margin: auto;
    width: 96vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-right-side .inner-hero {
    width: 320px;
    margin-left: 15px;
  }

  .hero-right-side .img-bg {
    width: 320px;
    margin-left: -15px;
  }

  .lhero-banner {
    width: 320px;
    height: 343px;
    object-fit: cover;
  }

  .point-text {
    justify-content: start;
    margin-bottom: 8px;
  }

  .no-justify {
    text-align: center!important;
  }

  .AboustUsWelcome .points {
    align-items: flex-start!important;
  }
}

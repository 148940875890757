.UsDifferent {
    margin: auto;
    width: 980px;
    margin-bottom: 140px;
}

.UsDifferent .section-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.UsDifferent .text {
    text-align: center;
    margin-bottom: 20px;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .UsDifferent {
        width: 90vw;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .UsDifferent {
        width: 96vw;
        margin-bottom: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .UsDifferent .section-title .title {
        font-size: 30px;
    }

    .UsDifferent .text {
        width: 90vw;
    }
}
.Testimonials {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 1240px;
    gap: 70px;
    margin-bottom: 140px;
}

.Testimonials .l-testimonials {
    position: relative;
    width: 480px;
    height: 420px;
}

.l-testimonials .primary {
    width: 68px;
    height: 68px;
    background-color: var(--primaryColor);
    border-radius: 50%;
    left: 0;
    position: absolute;
    margin-top: -160px;
    margin-left: 340px;
}

.l-testimonials .secondry {
    width: 48px;
    height: 48px;
    background-color: var(--subColor);
    border-radius: 50%;
    position: absolute;
}

.l-testimonials .user-one {
    margin-top: 0px;
    margin-left: 60px;
    border-radius: 50%;
}

.l-testimonials .user-two {
    position: absolute;
    margin-top: 280px;
    margin-left: -200px;
    border-radius: 50%;
}

.l-testimonials .user-three {
    margin-top: -50px;
    margin-left: 260px;
    border-radius: 50%;
}

.Testimonials .r-testimonials {
    width: 680px;
}

.r-testimonials .testimonial-header .title {
    font-size: 42px;
    font-weight: 800;
    color: var(--primaryColor);
}

.r-testimonials .testimonial-header .line {
    width: 100px;
    height: 4px;
    background-color: var(--subColor);
    margin-bottom: 30px;
}

.r-testimonials .testimonial-sub {
    font-size: 28px;
    font-weight: 500;
    color: var(--textColor);
    width: 320px;
    margin-bottom: 30px;
}

.swiper-wrapper {
    margin-bottom: 50px;
    cursor: pointer;
}

.custom-swiper .swiper-pagination {
    text-align: left;
}

.swiper-pagination .swiper-pagination-bullet {
    background: var(--subColor);
}
.swiper-pagination-bullet-active {
    background: var(--subColor);
}

.quote {
    color: var(--textColor);
    font-size: 14px;
    width: 620px;
}

.user {
    color: #545454;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: -16px;
}

.user-one.active, .user-two.active, .user-three.active {
    transform: scale(1.2); 
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Testimonials {
        width: 96vw;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .r-testimonials .testimonial-sub {
        width: 80vw;
        display: flex;
        align-items: center;
    }

    .quote {
        width: 80vw;
        text-align: justify;
    }
    
    .Testimonials .l-testimonials {
        display: none;
    }
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .Testimonials {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 94vw;
        margin-top: -490px;
    }

    .Testimonials .r-testimonials {
        width: 94vw;
    }

    .quote {
        line-height: 22px;
        width: 90vw;
    }

    .Testimonials .l-testimonials {
        display: none;
    }

    .r-testimonials .testimonial-header .title {
        font-size: 30px;
    }

    .r-testimonials .testimonial-sub {
        font-size: 24px;
    }

    .l-testimonials .user-one {
        width: 150px;
        margin-left: 32px;
        margin-bottom: 174px;
    }

    .l-testimonials .user-two {
        margin-left: -178px;
    }

    .l-testimonials .user-three {
        margin-left: 140px;
        margin-top: -260px;
        width: 210px;
    }

    .l-testimonials .primary {
        margin-left: 172px;
        margin-top: -324px;
    }

    .l-testimonials .secondry {
        margin-top: -140px;
        margin-left: 36px;
    }
}
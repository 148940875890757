/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .BookSession .landHero {
        height: 400px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .BookSession .landHero .hero-right-side {
        display: none;
    }

    .landHero .hero-left-side .hero-ctas {
        display: flex;
        flex-direction: column;
        row-gap: 24px!important;
        /* padding-bottom: 120px; */
    }

    /* .hero-left-side .hero-text {
        width: 300px;
    } */
}
.Welcome .conditions-text {
    width: 560px;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Welcome {
        width: 90vw;
    }

    .Welcome .welcome-header {
        width: 80vw;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .Welcome .welcome-header {
        font-size: 26px;
        width: 92vw;
    }

    .Welcome .conditions-text {
        width: 88vw;
    }
}
/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .EatingDisorders .landHero {
        height: 920px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .EatingDisorders .Welcome {
        width: 96vw
    }
}
.Footer {
    margin: auto;
    background-color: var(--containerColor);
    width: 100%;
}

.Footer .footer-main {
    margin: auto;
    display: grid;
    grid-template-columns: 390px 140px 200px auto;
    padding-top: 60px;
    gap: 60px;
    justify-content: center;
    margin-bottom: 80px;
}

.about-footer .footer-logo {
    width: 180px;
    margin-bottom: 30px;
}

.about-footer .footer-text {
    font-size: 12px;
    text-align: justify;
    color: var(--textColor);
    width: 380px;
    margin-bottom: 30px;
}

.footer-main .about-footer .btn {
    width: 100px;
}

.footer-main .footer-section {
    margin-top: 20px;
}

.footer-main .footer-section .footer-head {
    font-size: 20px;
    font-weight: 700;
    color: var(--primaryColor);
    margin-bottom: 30px;
}

.footer-section .footer-link a,
.footer-section .footer-link
{
    font-size: 14px;
    color: var(--textColor);
    margin-bottom: 16px;
    cursor: pointer;
}

.contact-footer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
}

.contact-link {
    margin-bottom: 60px!important;
}

.footer-section .footer-link a:hover,
.footer-section .footer-link:hover 
{
    color: var(--primaryColor);
}

.footer-section .icon-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    margin-bottom: 60px;
}

.icon-links .footer-icon {
    color: var(--textColor);
    cursor: pointer;
    font-size: 24px;
}

.icon-links .footer-icon:hover {
    color: var(--subColor);
}

.footer-section .footer-text {
    font-size: 12px;
    color: var(--textColor);
    text-align: justify;
    width: 360px;
    margin-bottom: 28px;
}

.footer-section .email-form form input {
    width: 350px;
    border-color: var(--primaryColor)!important;
    border: solid;
    border-width: 1px;
}

.Footer .copyright {
    width: 1280px;
    margin: auto;
    padding-bottom: 20px;
}

.copyright .seperator {
    width: 1280px;
    height: 1.6px;
    background-color: var(--primaryColor);
    margin-bottom: 20px;
}

.copyright .copy-text {
    font-size: 12px;
    color: var(--textColor);
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .Footer .footer-main {
        width: 90vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .Footer .copyright {
        width: 90vw;
    }

    .copyright .seperator {
        width: 90vw;
    }
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .Footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .Footer .copyright {
        width: 92vw;
    }

    .Footer .copyright .seperator {
        width: 90vw;
    }

    .Footer .footer-main {
        display: flex;
        flex-direction: column;
        width: 94vw;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
    }

    .Footer .footer-main .footer-section {
        width: 90vw;
    }

    .footer-main .about-footer {
        width: 94vw;
    }

    .about-footer .footer-text,
    .footer-section .footer-text
     {
        width: 90vw;
    }

    .footer-section .footer-link {
        margin-bottom: 10px;
    }
}
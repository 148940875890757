.AboustUsWelcome {
    margin: auto;
    display: flex;
    width: 1220px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 140px;
}

.AboustUsWelcome .about-welcome-left .section-title {
    margin-bottom: 30px;
}

.AboustUsWelcome .about-welcome-left .text {
    margin-bottom: 20px;
    width: 660px;
}

.about-welcome-left .btn {
    width: 100px;
}

.AboustUsWelcome .about-welcome-right .welcome-banner {
    border-radius: 30px;
}


/* Tablet Screens */
@media screen and (min-width: 600px) and (max-width: 1199px) {
    .AboustUsWelcome {
        display: flex;
        flex-direction: column;
        width: 92vw;
        gap: 60px;
    }

    .AboustUsWelcome .about-welcome-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboustUsWelcome .about-welcome-right {
        display: none;
    }

    .about-welcome-left .section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-welcome-left .text {
        text-align: center;
    }

    .about-welcome-left .section-title .title {
        text-align: center;
    }
}


/* Mobile styles properties */

@media screen and (min-width: 200px) and (max-width: 599px) {
    .AboustUsWelcome {
        width: 96vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 40px;
        margin-bottom: 100px;
    }

    .AboustUsWelcome .about-welcome-left,
    .AboustUsWelcome .about-welcome-right
    {
        width: 94vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboustUsWelcome .about-welcome-left .section-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboustUsWelcome .about-welcome-left .section-title .title {
        font-size: 30px;
        text-align: center;
    }

    .AboustUsWelcome .about-welcome-left .text {
        width: 94vw;
        text-align: center;
        margin-bottom: 20px;
    }

    .AboustUsWelcome .about-welcome-right {
        margin-top: 30px;
    }

    .AboustUsWelcome .about-welcome-right .welcome-banner {
        width: 84vw;
    }
}
.NarcissisticPersonalityDisorder .landHero {
    height: 640px;
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .NarcissisticPersonalityDisorder .landHero {
        height: 1100px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .NarcissisticPersonalityDisorder .Welcome {
        width: 96vw
    }
}
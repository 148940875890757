.Services {
    margin-bottom: 140px;
    display: flex;
    flex-direction: column;
}

.Services .service-title {
    text-align: center;
    margin-bottom: 36px;
}

.service-title .highlight {
    font-weight: 700;
}

.Services .main-services {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1290px;
    gap: 38px;
    padding: 0px 26px;
}

.main-services .service {
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.main-services .service:nth-child(4),
.main-services .service:nth-child(5),
.main-services .service:nth-child(6)
{
    margin-top: 30px;
}

.image-div {
    position: relative;
    box-sizing: border-box;
    margin: 10px;
    overflow: hidden;
}

.image-div img {
    width: 100%;
    height: auto;
    display: block;
}


.overlay {
    position: absolute;
    border-radius: 0 0 30px 30px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: rgba(255, 205, 3, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-div:hover .overlay {
    opacity: 1;
}

.service .image-div img {
    border-radius: 30px;
    width: 360px;
}

.service .service-text {
    font-size: 24px;
    font-weight: 700;
    color: var(--textColor);
}

.service-text .highlight {
    font-weight: 700;
}


/* Mobile Screens */
@media screen and (min-width: 200px) and (max-width: 599px) {
    .Services {
        display: flex;
        margin: auto;
    }

    .main-services {
        width: 80vw;
    }

    .Services .service-title {
        font-size: 30px;
    }

    .service .image-div img {
        width: 320px;
    }

    .service .service-text {
        font-size: 20px;
    }
}